<template>
  <div class="login">
    <img
        src="@/assets/images/brains-logo.svg"
        alt="Brains logo"
        class="login__logo"
    />
    <div class="login__content">
      <div class="login__greeting">
        <h1 class="login__tittle">Account login</h1>
      </div>
      <div class="login__form">
        <div>
          <label class="login__input-label">User name<br /> </label>
          <input class="login__input" id="nickName"
            placeholder="example@email.com"
            v-model="username"/>
        </div>
        <div>
          <label class="login__input-label"> Password<br /> </label>
          <input class="login__input" id="Password"
            type="password"
            v-model="password"
            @keyup.enter="login"/>
        </div>
        <button class="login__button" @click="login()">Log In</button>
      </div>
      <p class="login__freelancer" href="" @click="createFreelance()">Create new freelancer account</p>
      <!-- <a class="login__form-instruction"> Forgot your password? </a> -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    async login() {
      const formData = new FormData();
      formData.append('username', this.username);
      formData.append('password', this.password);
      const response = await this.$store.dispatch('user/login', formData);
      if (response.status === 200) {
        const decoded = this.$jwt.decode(response.data.access_token);
        const username = JSON.parse(decoded.sub);
        this.$store.commit('user/setLogedUsername', username.username);
        this.$store.commit('user/setLogedUserRole', decoded.role);
        localStorage.setItem('brains-register-token', response.data.access_token);
        if (this.$store.getters['user/getLogedUserRole'] === 'Freelancer') {
          this.$router.push({ path: `/applicant/freelancer/${username.id}` });
        }
        if (this.$store.getters['user/getLogedUserRole'] === 'Evaluator') {
          this.$router.push({ path: '/applicants/' });
        }
        if (this.$store.getters['user/getLogedUserRole'] === 'Super user') {
          this.$router.push({ path: '/administrator' });
        }
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Login fail',
          detail: 'User or password incorrect!',
          life: 4000,
        });
      }
    },
    createFreelance() {
      this.$router.push({ path: '/sign-up' });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
    display: flex;
    flex-direction: column;
    justify-items: center;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
  &__logo {
    width: 200px;
    margin: 0 auto 3rem;
  }
  &__tittle {
    font-size: 1.5rem;
  }
  &__content {
    display: grid;
    justify-items: center;
    margin: 0 1rem;
  }
  &__button {
    @include primary-button();
    min-width: 20rem;
    margin-top: 1rem;
  }
  &__input-label {
    margin: 0 0 0 0.5rem;
  }
  &__input {
    @include input(100%);
    min-width: 20rem;
    margin-bottom: 1rem;
  }
  &__form-instruction {
    margin: 1rem 0rem 0rem 0rem; //top right bottom left
    color: $primary-color;
    cursor: pointer;
  }
  &__freelancer {
    margin-top:6px;
    text-decoration: underline;
    cursor: pointer;
    &:hover{
      color: $primary-color;
    }
  }
}
</style>
